import "./bootstrap"
import "../css/app.scss"

import { createApp, h } from "vue"
import { createInertiaApp } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "../../vendor/tightenco/ziggy"
import AppLayout from "./Layouts/AppLayout.vue"
import Page from "@/Components/Page.vue"
import SectionPosition from "@/Components/SectionPosition.vue"
import { createPinia } from "pinia"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import AOS from "aos"
import t from "./translation-manager.js"
import langChanger from "./locale"
import { VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3"

gsap.registerPlugin(ScrollTrigger)
const pinia = createPinia()
AOS.init()
const appName = import.meta.env.VITE_APP_NAME || "OMD"

createInertiaApp({
	title: title => `${title} - ${appName}`,
	resolve: name => {
		const page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue"))
		page.then(module => {
			module.default.layout = module.default.layout || AppLayout
		})
		return page
	},
	setup({ el, App, props, plugin }) {
		const captchaKey = props.initialPage.props.recaptcha_site_key
		const VApp = createApp({ render: () => h(App, props) })
		VApp.use(plugin)
		VApp.use(ZiggyVue)
		VApp.use(pinia)
		VApp.use(VueReCaptcha, { siteKey: captchaKey })
		VApp.provide("ScrollTrigger", ScrollTrigger)
		VApp.provide("gsap", gsap)
		VApp.provide("t", t)
		VApp.config.globalProperties.$t = t
		VApp.config.globalProperties.$langChanger = langChanger
		VApp.component("AppLayout", AppLayout)
		VApp.component("Page", Page)
		VApp.component("SectionPosition", SectionPosition)
		VApp.mount(el)
	},

	progress: {
		color: "#4B5563"
	}
})
