<script setup>
import { Head } from "@inertiajs/vue3"
import { computed } from "vue"

defineProps({
	title: String,
	description: String,
	image: String
})

const getHref = computed(() => window.location.href)
</script>

<template>
	<Head>
		<title>{{ title }}</title>

		<meta :content="description" name="description" />
		<meta :content="title" property="og:title" />
		<meta :content="description" property="og:description" />
		<template>
			<meta v-if="image" :content="image" property="og:image" />
			<meta v-else content="@/assets/img/meta-preview.png" property="og:image" />
		</template>
		<meta :content="getHref" property="og:url" />
	</Head>
</template>

<style scoped></style>
