<script setup>
import { watch } from "vue"

const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	settings: Object
})
watch(
	() => props.show,
	newValue => {
		if (newValue) {
			document.querySelector("html").style.overflow = "hidden"
		} else {
			document.querySelector("html").style.overflow = null
		}
	}
)
</script>

<template>
	<div :class="{ active: show }" class="sidebar-menu">
		<div class="w-container flex h-full w-full items-center">
			<ul v-if="settings" class="space-y-5">
				<li v-if="settings?.lang?.en">
					<a :href="$langChanger('en')" class="text-4xl font-light uppercase underline 2xl:text-6xl">english</a>
				</li>
				<li>
					<a :href="$langChanger('ru')" class="text-4xl font-light uppercase underline 2xl:text-6xl">russian</a>
				</li>
				<li v-if="settings?.lang?.kk">
					<a :href="$langChanger('kk')" class="text-4xl font-light uppercase underline 2xl:text-6xl">kazakh</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sidebar-menu {
	@apply fixed inset-0 z-[60] h-full w-full translate-x-full bg-white transition-all duration-1000 ease-in-out;
	&.active {
		@apply translate-x-0;
	}
}
</style>
