<script setup>
import LogoBtn from "@/Components/ui/LogoBtn.vue"
import { ref, watch } from "vue"
import { usePage } from "@inertiajs/vue3"

const emit = defineEmits(["toggleNavMenu", "toggleSidebarMenu"])
const props = defineProps({
	isActiveNavMenu: Boolean,
	isActiveSidebarMenu: Boolean,
	settings: Object
})
const isAccentLogo = ref(false)
const page = usePage()
watch(
	() => props.isActiveSidebarMenu,
	newValue => {
		isAccentLogo.value = newValue
	}
)
const toggleNavMenu = () => {
	if (props.isActiveSidebarMenu) {
		toggleSidebarMenu()
	}
	emit("toggleNavMenu")
}
const toggleSidebarMenu = () => {
	if (props.isActiveNavMenu) {
		toggleNavMenu()
	}
	emit("toggleSidebarMenu")
}
const changeLogoStyle = val => {
	isAccentLogo.value = val
}
</script>

<template>
	<div class="pointer-events-none fixed inset-0 z-[70]">
		<div class="w-container py-10">
			<div class="flex items-center justify-between">
				<LogoBtn :is-accent="isAccentLogo" :is-active-nav-menu="isActiveNavMenu" @change-logo-style="changeLogoStyle" @toggle-nav-menu="toggleNavMenu" />
				<div v-if="settings.contacts" class="absolute right-5 top-1/2 hidden -translate-y-1/2 space-y-2 sm:block">
					<a v-if="settings?.contacts?.facebook" :href="settings.contacts.facebook" class="btn-circle" target="_blank">
						<img alt="" class="w-6" src="/assets/img/icons/facebook-logo.svg" />
					</a>
					<a v-if="settings?.contacts?.instagram" :href="settings.contacts.instagram" class="btn-circle" target="_blank">
						<img alt="" class="w-8" src="/assets/img/icons/instagram-logo.svg" />
					</a>
					<a v-if="settings?.contacts?.linkedin" :href="settings.contacts.linkedin" class="btn-circle" target="_blank">
						<img alt="" class="w-6" src="/assets/img/icons/linkedin-logo.svg" />
					</a>
				</div>
				<div class="absolute right-5 top-10 hidden sm:block">
					<button v-if="!isActiveSidebarMenu" class="btn-circle text-lg font-semibold uppercase" @click.prevent="toggleSidebarMenu">
						<span>{{ page.props.locale }}</span>
					</button>
					<button v-else class="pointer-events-auto" @click.prevent="toggleSidebarMenu">
						<svg class="w-9 xl:w-12" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M6 18 18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
				</div>
				<button class="pointer-events-auto block md:hidden" @click.prevent="toggleNavMenu">
					<svg class="w-10" fill="none" stroke="white" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
