<script setup>
import { ref, watch, onMounted } from "vue"
const props = defineProps({
	isActiveNavMenu: Boolean,
	isAccent: Boolean
})
const emit = defineEmits(["toggleNavMenu", "changeLogoStyle"])
const isHovered = ref(false)

onMounted(() => {
	window.addEventListener("scroll", onScroll)
})
watch(
	() => props.isActiveNavMenu,
	newValue => {
		if (!newValue) {
			isHovered.value = false
		}
	}
)

function onScroll() {
	if (window.scrollY > window.innerHeight) {
		emit("changeLogoStyle", true)
	} else {
		emit("changeLogoStyle", false)
	}
}
</script>

<template>
	<div class="logo relative" :class="{ 'is-hovered': isHovered, 'is-accent': isAccent }" @mouseover="isHovered = true" @mouseleave="!isActiveNavMenu ? (isHovered = false) : (isHovered = true)">
		<button @click.prevent="$emit('toggleNavMenu')" class="">
			<transition
				enter-active-class="ease-out delay-500 duration-300"
				enter-from-class="opacity-0 translate-y-5"
				enter-to-class="opacity-100 translate-y-0"
				leave-active-class="ease-in duration-300"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<svg v-if="!isActiveNavMenu && isHovered" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
				</svg>
				<svg v-else-if="isActiveNavMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
				</svg>
			</transition>
		</button>
		<svg class="logo-part" xmlns="http://www.w3.org/2000/svg" width="54" height="39">
			<path
				fill=""
				fill-rule="evenodd"
				d="M53.98 11.14C53.98 5.326 49.515.053 40.882.053h-8.691v10.441h-11.34l-4.591 8.518-4.802-8.518h-6.01l.002-.006H.099v28.515h9.935V27.305l6.226 8.97 6.014-8.97v11.698h9.99V22.897h8.359c8.695 0 13.373-4.867 13.373-11.428l-.016-.329zm-7.713.252c0 2.803-2.167 4.023-4.735 4.023h-1.483V7.309h1.461c2.59 0 4.757 1.223 4.757 4.028v.055z"
			></path>
		</svg>
	</div>
</template>

<style scoped lang="scss">
.logo {
	@apply pointer-events-auto flex w-fit;
	button {
		@apply z-[60] -mt-[10px] flex h-9 w-9 items-center justify-center rounded-full border-[12px] border-white bg-transparent transition-all delay-300 duration-500 ease-in-out;
		svg {
			@apply absolute w-8 stroke-accent 2xl:w-10;
		}
	}
	&-part {
		@apply visible -ml-[5px] fill-white opacity-100 transition-all delay-300 duration-500 ease-in-out;
	}
	&.is-accent {
		.logo-part {
			@apply fill-accent;
		}
		button {
			@apply border-accent;
		}
	}
	&.is-hovered {
		button {
			@apply h-16 w-16 border border-gray-300 bg-white 2xl:h-20 2xl:w-20;
		}
		.logo-part {
			@apply invisible translate-y-5 opacity-0;
		}
	}
}
</style>
