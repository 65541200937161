import { router, usePage } from "@inertiajs/vue3"

const page = usePage()

function removeLastSlash(str) {
	if (str.charAt(str.length - 1) === "/") {
		return str.slice(0, -1)
	} else {
		return str
	}
}

const getLocalizedUrl = (lang = "en", url = null) => {
	url = url === null ? route(route().current(), route().params) : url
	const langs = ["kk", "ru", "en"]
	if (lang === null) {
		lang = page.props.locale
	}

	if (url == null || url.split("/").pop() === null) {
		url = window.location.href.replace("/public/public")
	}
	url = url.replace("/public/public", "")
	url = removeLastSlash(url)
	if (!url.startsWith("http://") && !url.startsWith("https://")) {
		url = new URL(url, window.location.origin)
	} else {
		url = new URL(url)
	}

	const params = url.search
	url = url.pathname.split("/")
	if (!langs.includes(url[1])) {
		url.splice(1, 0, lang)
	} else {
		url[1] = lang
	}
	return url.join("/") + params
}
export default getLocalizedUrl
