<script setup>
import { Head } from "@inertiajs/vue3"
import NavMenu from "@/Components/NavMenu.vue"
import Navbar from "@/Components/Navbar.vue"
import { onMounted, ref, computed, watch } from "vue"
import { router, usePage } from "@inertiajs/vue3"
import { useMain } from "@/Stores/main.js"

const mainStore = useMain()
const page = usePage()
const props = defineProps({
	menu: Array,
	settings: Object
})

// const links = ref([
// 	{
// 		title: "Home",
// 		link: "page.home"
// 	},
// 	{
// 		title: "Why OMD?",
// 		link: "page.why_omd"
// 	},
// 	{
// 		title: "People",
// 		link: "page.people"
// 	},
// 	{
// 		title: "Work",
// 		link: "page.works"
// 	},
// 	{
// 		title: "Thoughts",
// 		link: "page.thoughts"
// 	},
// 	{
// 		title: "Contacts",
// 		link: "page.contacts"
// 	}
// 	// {
// 	// 	title: "Privacy Policy",
// 	// 	link: "/page/privacy-policy"
// 	// }
// ])
const isHomePage = computed(() => {
	return router.page?.props?.page?.slug === "home" ? true : false
})
const getShowNavbar = computed(() => {
	if (window.screen.width < 640) {
		mainStore.showNavbar = false
		return false
	} else {
		return mainStore.showNavbar
	}
})
onMounted(() => {
	if (isHomePage.value) {
		mainStore.showNavbar = false
	} else {
		mainStore.showNavbar = true
	}
})
</script>

<template>
	<NavMenu :links="menu" :settings="settings" />
	<main>
		<slot />
	</main>
	<transition name="slide-fade">
		<Navbar v-if="getShowNavbar" :is-home="isHomePage" :links="menu" :page="page" />
	</transition>
</template>

<style scoped></style>
