<script setup>
import { Link } from "@inertiajs/vue3"
import { ref, watch } from "vue"
import FixedTools from "@/Components/FixedTools.vue"
import { router } from "@inertiajs/vue3"
import SidebarMenu from "@/Components/SidebarMenu.vue"
import Navbar from "@/Components/Navbar.vue"

const props = defineProps({
	links: Array,
	settings: Object
})
const isActiveMenu = ref(false)
const isActiveSidebarMenu = ref(false)
router.on("finish", event => {
	isActiveMenu.value = false
})

watch(isActiveMenu, newValue => {
	if (newValue) {
		document.querySelector("html").style.overflow = "hidden"
	} else {
		document.querySelector("html").style.overflow = null
	}
})
</script>

<template>
	<FixedTools
		:is-active-nav-menu="isActiveMenu"
		:is-active-sidebar-menu="isActiveSidebarMenu"
		:settings="settings"
		@toggle-sidebar-menu="isActiveSidebarMenu = !isActiveSidebarMenu"
		@toggle-nav-menu="isActiveMenu = !isActiveMenu"
	/>
	<SidebarMenu :settings="settings" :show="isActiveSidebarMenu" />
	<div :class="{ active: isActiveMenu }" class="nav">
		<div class="nav-content w-container">
			<div class="mb-5">
				<nav>
					<ul class="space-y-2 2xl:space-y-4">
						<li v-for="(item, index) in links" :key="index">
							<a :href="route(item.link)" class="inline-block text-4xl font-light transition-all duration-300 ease-in-out hover:translate-x-5 hover:text-accent 2xl:text-6xl">
								{{ item.title }}
							</a>
						</li>
					</ul>
				</nav>
			</div>
			<div class="flex flex-col justify-between gap-5 lg:flex-row lg:items-center">
				<ul class="flex flex-wrap divide-x divide-gray-300">
					<li v-for="(item, index) in links" :key="index" class="px-2 first:pl-0 lg:px-4">
						<a :href="route(item.link)" class="font-light uppercase">{{ item.title }}</a>
					</li>
				</ul>
				<p class="heading-xl font-heading">That’s what we do. Where next.</p>
			</div>
		</div>
		<div class="backdrop"></div>
	</div>
</template>

<style lang="scss" scoped>
.nav {
	@apply invisible fixed inset-0 z-50 h-full w-full overflow-hidden bg-white transition-all duration-1000 ease-in-out;
	.backdrop {
		@apply invisible absolute left-5 top-10 z-50 h-10 w-10 overflow-hidden rounded-full bg-white transition-all duration-1000 ease-in-out md:left-10 xl:left-20;
	}

	&-content {
		@apply invisible relative z-[70] flex h-full flex-col justify-center overflow-hidden py-10 opacity-0 transition-all duration-300 ease-in-out;
	}

	&.active {
		.backdrop {
			@apply visible left-[-200vw] top-[-200vw] h-[500vw] w-[500vw] opacity-100 md:h-[400vw] md:w-[400vw];
		}

		.nav-content {
			@apply visible w-auto opacity-100 delay-1000;
		}
	}
}
</style>
