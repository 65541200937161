<script setup>
import { ref, computed, onMounted, watch } from "vue"
import Logo from "@/Components/ui/Logo.vue"
import { Link, router } from "@inertiajs/vue3"
import { useMain } from "@/Stores/main.js"

const mainStore = useMain()
const props = defineProps({
	links: Array,
	page: Object,
	isHome: {
		type: Boolean,
		default: false
	}
})

onMounted(() => {
	const navbar = document.querySelector(".navbar")
	mainStore.navbarHeight = navbar.getBoundingClientRect().height
	if (!props.isHome) {
		navbar.classList.add("is-footer")
	}
})
watch(
	() => props.page,
	newValue => {
		const navbar = document.querySelector(".navbar")
		if (navbar) {
			if (!props.isHome) {
				navbar.classList.remove("is-footer")
			} else {
				navbar.classList.add("is-footer")
			}
		}
	},
	{ deep: true }
)
</script>

<template>
	<div class="navbar">
		<div class="w-container flex items-center justify-between">
			<Logo class="w-12" />
			<ul>
				<li v-for="(item, index) in links" :key="index">
					<a :href="route(item.link)">{{ item.title }}</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.navbar {
	@apply fixed bottom-0 left-0 right-0 z-50 bg-white py-2 transition-all delay-1000 duration-300 ease-in-out;

	&.is-footer {
		@apply relative;
	}

	ul {
		@apply flex items-center gap-10;
		li {
			a {
				@apply inline-block transition duration-300 ease-in-out;
				&:hover {
					@apply text-accent;
				}
			}
		}
	}
}
</style>
