<script setup></script>

<template>
	<svg viewBox="0 0 84 53" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M70.623 36.897h-8.359v16.106h-9.99V41.305l-6.014 8.97-6.226-8.97v11.698h-9.935V32.258c-3.193 2.786-7.342 4.485-11.884 4.485C8.177 36.743.009 28.502.009 18.372.009 8.241 8.177 0 18.215 0s18.206 8.241 18.206 18.372c0 2.146-.385 4.199-1.059 6.116h.088l-.002.006h6.01l4.802 8.518 4.591-8.518h11.34V14.053h8.691c8.633 0 13.098 5.273 13.098 11.087l.016.329c0 6.561-4.678 11.428-13.373 11.428zM18.215 12.206c-3.368 0-6.109 2.767-6.109 6.166 0 3.399 2.741 6.165 6.109 6.165 3.369 0 6.109-2.766 6.109-6.165s-2.74-6.166-6.109-6.166zm58.052 13.131c0-2.805-2.167-4.028-4.757-4.028h-1.461v8.106h1.483c2.568 0 4.735-1.22 4.735-4.023v-.055z"
			fill="#eb2a21"
			fill-rule="evenodd"
		></path>
	</svg>
</template>

<style scoped></style>
